<template>
    <section class="pa-4 formular">
        <v-container>
            <v-row :class="{ 'rgg-grey' : $vuetify.theme.dark }" class="grey lighten-4 rounded mb-4 pt-1">
                <!--RANGE VALID-->
                <v-col cols="6">
                    <v-dialog
                        ref="picker_validFrom"
                        v-model="picker_validFrom"
                        :return-value.sync="range.from"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="range.from"
                                :label="$t('salary_reports.dialog.valid_from')"
                                prepend-inner-icon="mdi-calendar"
                                readonly hide-details
                                v-bind="attrs"
                                v-on="on"
                                outlined dense
                                color="gold"
                            />
                        </template>
                        <v-date-picker
                            v-model="validFrom"
                            scrollable
                            :first-day-of-week="1"
                            locale="sk"
                            color="gold"
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="gold"
                                @click="picker_validFrom = false"
                            >
                                {{ $t('common.cancel') }}
                            </v-btn>
                            <v-btn
                                text
                                color="gold"
                                @click="$refs.picker_validFrom.save(validFrom);"
                            >
                                {{ $t('common.ok') }}
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col cols="6">
                    <v-dialog
                        ref="picker_validTo"
                        v-model="picker_validTo"
                        :return-value.sync="range.to"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="range.to"
                                :label="$t('salary_reports.dialog.valid_to')"
                                prepend-inner-icon="mdi-calendar"
                                readonly hide-details
                                v-bind="attrs"
                                v-on="on"
                                outlined dense
                                color="gold"
                            />
                        </template>
                        <v-date-picker
                            v-model="validTo"
                            scrollable
                            :first-day-of-week="1"
                            locale="sk"
                            color="gold"
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="gold"
                                @click="picker_validTo = false"
                            >
                                {{ $t('common.cancel') }}
                            </v-btn>
                            <v-btn
                                text
                                color="gold"
                                @click="$refs.picker_validTo.save(validTo);"
                            >
                                {{ $t('common.ok') }}
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
            </v-row>
            <v-row :class="{ 'rgg-grey' : $vuetify.theme.dark }" class="grey lighten-4 rounded mb-4 pt-1">
                <!--ACTION-->
                <v-col cols="12" align="center">
                    <v-btn dense depressed color="gold" dark @click="generate">
                        <v-icon left>mdi-cog-play-outline</v-icon>
                        <span>{{ $t(options.localize) }}</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import {sendAgreementMonthlyEmail} from "@/api/agreements";

export default {
    name: "MonthlyStatementEmailDialog",
    props: {
        edit: Boolean,
        id: undefined,
        options: Object
    },
    data: () => ({
        picker_validFrom: false,
        picker_validTo: false,
        range: {
            from: "",
            to: ""
        },
        validFrom: "",
        validTo: "",
    }),
    created() {
        let currentDate = new Date()
        let endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
        endDate.setDate(endDate.getDate() - 1)
        let month = endDate.getMonth() + 1
        if (month < 10) {
            month = "0" + month
        }
        this.range.from = endDate.getFullYear() + "-" + month + "-01"
        this.range.to = endDate.getFullYear() + "-" + month + "-" + endDate.getDate()
    },
    methods: {
        generate() {
            this.$root.$emit('overlay', true);
            sendAgreementMonthlyEmail(this.id, this.range)
                .then((response) => {
                    this.$root.$emit('overlay', false);
                    this.$emit('close');
                    if (response) {
                        this.$root.$emit('notification', {
                            type: 'success',
                            icon: 'update',
                            msg: 'notification.send_monthly_report'
                        });
                        this.$parent.$emit('call', {func: 'close', data: ''});
                    } else {
                        this.$root.$emit('notification', {
                            type: 'error',
                            icon: 'server_error',
                            msg: 'notification.send_monthly_report_error'
                        });
                    }
                }).catch(() => {
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })
        }
    }
}
</script>